<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />

    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Edit {{ dataType }} </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">{{ dataType }} </span>
            <v-text-field
              ref="name"
              color="#FDCE48"
              class="formClass"
              placeholder="Name"
              dense
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2 v-if="dataType === 'Crop Types'" pt-4>
            <!-- <span class="itemValue">Extend/Number</span> -->
            <!-- <v-select
            :items="extendTypes"
                  placeholder="Extend/Number"
                  dense
              v-model="isExtent"
            ></v-select> -->
            <v-checkbox
      v-model="isExtent"
      label="Is Extend?"
    ></v-checkbox>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #fff">Edit {{ dataType }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "dataType", "editUrl", "subItemId"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      name: null,
      isExtent: null,
    };
  },
  beforeMount() {
    this.name = this.storage.name;
    this.isExtent = this.storage.isExtent;
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Edit",
        getData: false,
        editUser: false,
      });
    },

    validateInput() {
      if (!this.name) {
        this.msg = "Please Provide " + this.dataType + " name";
        this.showSnackBar = true;
        return;
      } else {
        this.editdDynamicData();
      }
    },
    editdDynamicData() {
      var data = {};
      data["name"] = this.name;
      if (this.dataType == "Mitigation Method")
        data["mitigationTypeId"] = this.subItemId;
        if (this.dataType == "Crop Types")
        data["isExtent"] = this.isExtent;
      axios({
        method: "post",
        url: this.editUrl + this.storage._id,
        data: data,
        headers: {
          'x-auth-token': localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.password = null;
            this.msg = this.dataType + " Edited";
            this.showSnackBar = true;

            this.$emit("stepper", {
              type: "Edit",
              getData: true,
              editData: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>